import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { FiGithub, FiLinkedin } from 'react-icons/fi'

import styles from "../style/header.module.css"

const Header = ({siteTitle}) => {

  return(
    <header className={styles.siteHeader}>
      <h1>Paul Jang</h1>
    {/* Navigation menu */}
      <nav>
          <ul className={styles.siteNavigation}>
            <li className={styles.nav1}>
              <Link activeClassName={styles.selectedNav} to="/">
                Home
              </Link>
            </li> {/*Link based on page name */}
            <li className={styles.nav2}>
              <Link activeClassName={styles.selectedNav} to="/about">
                More Info & Projects
              </Link>
            </li>
            <li className={styles.nav3}>
              <Link activeClassName={styles.selectedNav} to="/contact">
                Contact
              </Link>
            </li>
            <div className={styles.otherWebsites}>
              <li className={styles.navLinked}>
                <a href="https://www.linkedin.com/in/jangpaul/">
                  <FiLinkedin/>
                </a>
              </li>
              <li className={styles.navGit}>
                <a href="https://github.com/pjangb">
                  <FiGithub/>
                </a>
              </li>  
            </div>
          </ul>
      </nav>
  </header>
)
  
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
