import React from 'react'
import { Link } from "gatsby"
import { FiHome, FiMail, FiFolderPlus, FiGithub, FiLinkedin } from 'react-icons/fi'


import styles from "../style/mobile.module.css"

//Include GH, Linked In, Bitbucket?
const MobileMenu = () => {

    return(
        <div className={styles.mobileNav}>

            <ul>
                <li className={styles.nav1}>
                    <Link activeClassName={styles.selectedMobileNav} to="/">
                        <FiHome/><br/>Home</Link>
                </li> {/*Link based on page name */}
                <li className={styles.nav2}>
                    <Link activeClassName={styles.selectedMobileNav} to="/about">
                        <FiFolderPlus/><br/><p>Projects +</p>
                    </Link>
                </li>
                <li className={styles.nav3}>
                    <Link activeClassName={styles.selectedMobileNav} to="/contact">
                        <FiMail/><br/>Contact
                    </Link>
                </li>
                <li style={{display: 'inline-block'}}>
                    <a href="https://www.linkedin.com/in/jangpaul/">
                        <FiLinkedin/><br/>LinkedIn
                    </a>
                </li>
                <li style={{display: 'inline-block'}}>
                    <a href="https://github.com/pjangb">
                        <FiGithub/><br/>Github
                    </a>
                </li>
            </ul>
        </div>
    )

    }

export default MobileMenu
